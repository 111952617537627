
import eventConstants from './eventConstants'

// Custom track event for use in code
export function track(eventName, eventPayload = {}) {
  if (typeof window === 'undefined') {
    return
  }
  if (!eventName) {
    throw new Error(
      'Argument eventName is required when invoking track. Received argument',
      eventName
    )
  }

  eventPayload = {
    ...eventPayload,
    'Platform': 'Web'
  }

  // Track on Heap
  if (!window.heap) {
    return
  }
  try {
    window.heap.track(eventName, eventPayload)
  } catch (err) {
    console.error('Heap tracking error', err)
  }

  // Track on Google Analytics
  if (!window.ga) {
    return
  }
  if ([
    eventConstants.home.pageLoad,
    eventConstants.yourData.pageLoad,
    eventConstants.app.pageLoad,
    eventConstants.privacy.pageLoad,
    eventConstants.terms.pageLoad
  ].includes(eventName)) {
    window.ga('send', 'event', {
      eventCategory: 'All',
      eventAction: 'Brochure Action',
    })
  } else {
    window.ga('send', 'event', {
      eventCategory: 'All',
      eventAction: eventName,
    })
  }
  // console.log('EventTracking', eventName, eventPayload)
}
