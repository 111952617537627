import Icon from './Icon'
import Bigfish from './Bigfish.svg'
import Caret from './Caret.svg'
import CaretAlt from './CaretAlt.svg'
import Email from './Email.svg'
import Inbox from './inbox.svg'
import People from './People.svg'
import Receipts from './Receipts.svg'
import Rollup from './rollup.svg'
import Shipping from './Shipping.svg'
import ShippingAlt from './ShippingAlt.svg'
import Travel from './Travel.svg'
import Unsubscribe from './unsubscribe.svg'

export {
  Bigfish,
  Caret,
  CaretAlt,
  Email,
  Inbox,
  People,
  Receipts,
  Rollup,
  Shipping,
  ShippingAlt,
  Travel,
  Unsubscribe,
}
export default Icon
