import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import tachyons from "styled-components-tachyons";

const Svg = styled.svg`
  vertical-align: middle;
  display: inline-block;
  width: ${props => props.size}px;
  height: ${props => props.size}px;
  ${tachyons};

  path {
    fill: inherit;
  }
`;

const Icon = props => {
  return (
    <Svg height={`${props.size}px`} viewBox="0 0 32 32" {...props}>
      <path d={props.type} />
    </Svg>
  );
};

Icon.propTypes = {
  type: PropTypes.string.isRequired,
  size: PropTypes.oneOf([16, 24, 32, 38, 42, 48, 64, 128])
};

Icon.defaultProps = {
  size: 16
};

export default Icon;
